<template>
    <b-modal :title="$t('material.suppliers.supplier')" v-model="modalActive" no-close-on-backdrop size="lg">
        <template #modal-header>
            <b-tabs-header :tabs="getTitles" @clickTab="handleClickTab" :activeTab.sync="activeTab" @close="modalActive = false" />
        </template>
        <template #default>
            <div>
                <validation-observer ref="validation">
                    <div v-show="activeTab === 0" :title="$t('material.suppliers.supplier')">
                        <b-form-group :label="$t('general.tax_number')">
                            <validation-provider #default="{ errors }" :rules="TAXValidationRules" :name="$t('general.tax_number')">
                                <b-row class="tax_number_row">
                                    <div>
                                        <b-form-input ref="taxNumber" autofocus @input="checkTAXMatch" v-model="addObject.tax_number"/>
                                    </div>
                                    <div>
                                        <b-button @click="search(errors[0])" variant="outline-secondary"><feather-icon icon="SearchIcon"/>
                                        </b-button>
                                    </div>
                                </b-row>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.name')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                                <b-form-input v-model="addObject.name"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.address')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.address')" rules="required">
                                <b-form-input v-model="addObject.address"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.country')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.country')" rules="required">
                                <b-form-input v-model="addObject.country"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div v-show="activeTab === 1" :title="$t('form_data.contact_person')">
                        <b-form-group :label="$t('form_data.person_name')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.person_name')" :rules="getContactNameRules">
                                <b-form-input ref="contactPersonName" autofocus v-model="contactPerson.name"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.email')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.email')" :rules="getContactMailRules" >
                                <b-form-input v-model="contactPerson.email"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.phone')" style="position: relative;">
                            <span style="position: absolute; right: 10px; margin-top: 10px;">
                                <fa class="ml-1" id="supported-phone-formats-tooltip" icon="question-circle" color="#72a5d8" />
                                <b-tooltip target="supported-phone-formats-tooltip" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                                    {{$t('general.supported_formats')}}:<br>
                                    <span v-html="$supported_phone_formats"/>
                                </b-tooltip>
                            </span>
                            <validation-provider #default="{ errors }" :name="$t('form_data.phone')" :rules="getContactPhoneRules">
                                <b-form-input v-model="contactPerson.phone"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <div class="d-flex justify-content-end">
                            <b-button variant="secondary" @click="addContactPerson">
                                <feather-icon icon="PlusIcon" class="mr-50"/>
                                <span class="align-middle">{{ $t('general.add') }}</span>
                            </b-button>
                        </div>
                        <div v-if="addObject.contacts && addObject.contacts.length > 0" class="mt-1">
                            <Table :columnDefs="columnDefs" :rowData="addObject.contacts" @deleteRow="removeContactPerson" @edit="editContactPerson"/>
                        </div>
                    </div>
                </validation-observer>
            </div>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.save') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {
        BButton,
        BFormGroup,
        BFormInput,
        BModal,
        BRow,
        BTooltip
    } from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import Table from '@/views/components/Table/Table'
    import {required, email} from '@validations'
    import * as Sentry from '@sentry/vue'
    import BTabsHeader from '@/views/components/BTabsHeader.vue'

    export default {
        components: {
            BTabsHeader,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BTooltip,
            ValidationProvider,
            ValidationObserver,
            Table,
            BRow
        },
        props: {
            vats: {
                type: Array
            }
        },
        data() {
            return {
                modalActive: false,
                addObject: {
                    name: '',
                    address: '',
                    country: '',
                    tax_number: '',
                    contacts: []
                },
                id: 0,
                contactPerson: {
                    name: '',
                    email: '',
                    phone: ''
                },
                columnDefs: [
                    {headerName: this.$t('table_fields.name'), field: 'name', filter: true},
                    {headerName: this.$t('table_fields.email'), field: 'email', filter: true},
                    {headerName: this.$t('table_fields.phone'), field: 'phone', filter: true},
                    {
                        headerName: this.$t('table_fields.actions'),
                        editable: false,
                        filter: false,
                        sortable: false,
                        colId: 'actions',
                        cellRenderer: 'btnCellRenderer',
                        cellRendererParams: () => {
                            return {button: 'deleteBtn', removeObject: true}
                        },
                        maxWidth: 100,
                        minWidth: 100
                    }
                ],
                required,
                email,
                editing: false,
                foundSupplier: {},
                lastFoundSupplier: {},
                found: false,
                activeTab: 0
            }
        },
        methods: {
            handleClickTab(index) {
                this.activeTab = index
            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = data
                    this.lastFoundSupplier = JSON.parse(JSON.stringify(data))
                    this.editing = true
                    this.id = this.addObject.contacts.length
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        address: '',
                        country: '',
                        tax_number: '',
                        contacts: []
                    }
                }
                this.modalActive = true
                this.activeTab = 0
            },
            validate() {
                this.$refs.validation.validate().then((success) => {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    } else {
                        this.$printError(this.$t('print.error.on_add'))
                    }
                })
            },
            checkTAXMatch() {
                if (this.found) {
                    this.addObject.name = ''
                    this.addObject.address = ''
                    this.addObject.country = ''
                }
            },
            async search(err) {
                if (err === undefined) {
                    if (this.addObject.tax_number.length === 8 || this.addObject.tax_number.length === 10) {
                        try {
                            if (this.addObject.tax_number.substring(0, 2).toLowerCase() === 'si') {
                                this.addObject.tax_number = this.addObject.tax_number.toUpperCase()
                            }
                            this.addObject.tax_number = this.addObject.tax_number.trim()

                            const response = await this.$http.post('/api/client/v1/tax_numbers/full', {'tax_number': this.addObject.tax_number})
                            this.foundSupplier = response.data ?? {}
                            this.addObject.name = this.foundSupplier.name
                            this.addObject.address = this.foundSupplier.address
                            this.addObject.country = this.foundSupplier.country
                            this.found = true
                        } catch (err) {
                            Sentry.captureException(err)
                            this.$printWarning(this.$t('print.warning.supplier_not_found'))
                            this.found = false
                            this.addObject.name = ''
                            this.addObject.address = ''
                            this.addObject.country = ''
                        }
                    } else {
                        try {
                            if (/^[A-Za-z]{2}/.test(this.addObject.tax_number)) {
                                this.addObject.tax_number = this.addObject.tax_number.toUpperCase()
                            }
                            const response = await this.$http.post('/api/client/v1/tax_numbers/full', {'tax_number': this.addObject.tax_number})
                            this.foundSupplier = response.data ?? {}
                            this.addObject.name = this.foundSupplier.name
                            this.addObject.address = this.foundSupplier.address
                            this.addObject.country = this.foundSupplier.country
                            this.found = true
                        } catch (err) {
                            Sentry.captureException(err)
                            this.$printWarning(this.$t('print.warning.supplier_not_found'))
                            this.found = false
                            this.addObject.name = ''
                            this.addObject.address = ''
                            this.addObject.country = ''
                        }
                    }
                } else {
                    this.$printWarning(this.$t('print.warning.tax_number_incorrect_input'))
                    this.found = false
                    this.foundSupplier = []
                    this.addObject.name = ''
                    this.addObject.address = ''
                    this.addObject.country = ''
                }

            },
            async add() {
                try {
                    const response = await this.$http.post('/api/client/v1/suppliers/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        address: '',
                        country: '',
                        tax_number: '',
                        contacts: []
                    }
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'supplierWithTaxNumberExists')) {
                        this.$printWarning(this.$t('print.warning.supplier_with_tax_number_exists'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_add'))
                    }
                }
            },
            async edit() {
                try {
                    await this.$http.put(`/api/client/v1/suppliers/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        address: '',
                        country: '',
                        tax_number: '',
                        contacts: []
                    }
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'supplierWithTaxNumberExists')) {
                        this.$printWarning(this.$t('print.warning.supplier_with_tax_number_exists'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_edit'))
                    }
                }
            },
            async addContactPerson() {
                this.$refs.validation.validate().then((success) => {
                    if (success && this.contactPerson.name !== '' && this.contactPerson.email !== '' && this.contactPerson.phone !== '') {
                        this.addObject.contacts.push(this.contactPerson)
                        this.contactPerson = {
                            name: '',
                            email: '',
                            phone: ''
                        }
                        this.$refs.validation.reset()
                    }
                })
            },
            async removeContactPerson(data) {
                const index = this.addObject.contacts.indexOf(data)
                if (index > -1) {
                    this.addObject.contacts.splice(index, 1)
                }
            },
            editContactPerson(params) {
                this.addObject.contacts[params.rowIndex].quantity = parseInt(params.newValue)
            }
        },
        computed: {
            getContactNameRules() {
                if (this.contactPerson.email !== '' || this.contactPerson.phone !== '') {
                    return 'required'
                } else {
                    return ''
                }
            },
            getContactMailRules() {
                if (this.contactPerson.name !== '' || this.contactPerson.phone !== '') {
                    return 'email|required'
                } else {
                    return ''
                }
            },
            getContactPhoneRules() {
                if (this.contactPerson.name !== '' || this.contactPerson.email !== '') {
                    return 'phone_number|required'
                } else {
                    return ''
                }
            },
            TAXValidationRules() {
                if (this.addObject.tax_number.substring(0, 2).toLowerCase() === 'si') {
                    return 'required|tax'
                } else {
                    return 'required|alpha_num'
                }
            },
            getTitles() {
                return [{title: this.$t('material.suppliers.supplier')}, {title: this.$t('form_data.contact_person')}]
            }
        }
    }
</script>

<style scoped>
.button-x {
  position: absolute;
  right: 0;
  background-color: transparent !important;
  color: #5E5873 !important;
  border: none !important;
  font-size: 34px;
  margin: 0 !important;
  padding: 0 !important;
}

.button-x:hover {
  box-shadow: none !important;
}

.tax_number_row {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 0;
  gap: 1.4rem;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
